$base-color: #e2e2e2;
$shine-color: rgba(222, 222, 222, 0.4);

.rc {
  display: flex;
  align-content: center;
  padding: 1.2rem 0;

  &__image-wrapper {
    margin-right: 2rem;
    border-radius: 0.6rem;
  }

  &__image {
    height: 5rem;
    width: 5rem;
    border-radius: 0.6rem;
  }

  &__info {
  }

  &__date {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--highlight-primary);
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--text-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1rem;
  }

  &__from {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-ternary);
  }
  &__venue {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-ternary);
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__footer {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-secondary);
    margin-top: 1rem;
  }

  &__needed {
    margin-left: 1rem;
  }
}

.rc:hover {
  .rc__title {
    color: var(--highlight-primary);
  }
}

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 60rem;
}

.rcs {
  display: flex;
  align-content: center;
  margin-bottom: 2rem;
  padding: 1.2rem;

  &__image-wrapper {
    height: 5rem;
    width: 6rem;
    overflow: hidden;
    border-radius: 0.6rem;

    @include background-gradient;
    animation: animate-step-lines 1.2s infinite linear;
  }

  &__image {
    height: 5rem;
    width: 5rem;
    border-radius: 0.6rem;
  }

  &__info {
    width: 100%;
    padding-left: 2rem;
  }

  &__title {
    height: 1rem;
    width: 70%;
    @include background-gradient;
    animation: animate-step-lines 1.2s infinite linear;
  }

  &__description {
    margin-top: 2rem;
    height: 2rem;
    width: 80%;
    @include background-gradient;
    animation: animate-step-lines 1.2s infinite linear;
  }
}

@keyframes animate-step-lines {
  0% {
    background-position: -10rem;
  }

  40%,
  100% {
    background-position: 20rem;
  }
}

@import '../../../sass/abstracts/mixins';

.v-search {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: 999999999;
  font-family: 'Baloo Da 2';
  display: flex;
  flex-direction: column;

  &__body {
    width: 100%;
    height: 100%;
    overflow-y: auto ;
    align-self: center;

    @include breakpoint(mobileonly) {
      width: 100%;
      padding: 0 1rem;
    }

    @include breakpoint(phablet) {
      width: 100%;
      padding: 0 1rem;
    }
  }

  &__tabs{
    background: #f2f2f2;
    color: #999;
    padding: .5rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    border: .1rem solid var(--text-ternary);

    &:active,&:hover,&:focus{
      outline: none;
    }

    &--active{
      font-size: 1.2rem;
      background: white;
      color: #333;
    }
  }

  &__body-heading {
    padding: 1rem 0;
    padding: 2rem;

    @include breakpoint(mobileonly) {
      padding: 2rem 0;
    }

    @include breakpoint(phablet) {
      padding: 2rem 0;
    }
  }

  &__results {
    overflow-y: auto;
    min-height: 25rem;
    max-height: 35rem;
    padding: 0 2rem;
    margin-bottom: 2rem;

    &::after{
      content: "";
      flex: auto;
    }

    @include breakpoint(mobileonly) {
      padding: 0;
    }

    @include breakpoint(phablet) {
      padding: 0;
    }
  }

  &__no-results{
    display: flex;
    justify-content: center;
    align-items: center;

    .imh {
      height: 12rem;
      margin: 2rem;
    }
  }
}


.dashboard {
  font-family: 'Baloo Da 2';

  &__heading {
    margin-bottom: 2rem;
    font-size: 2.8rem;
    font-weight: 600;
  }

  .sub-headers {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 500;
  }

  .part-1-stats {
    display: flex;
    width: 100%;
    // justify-content: space-between;
    margin-bottom: 3rem;

    .new {
      width: 50% !important;
      margin-left: 11rem;
      padding: 2rem 4rem !important;
    }

    .wishlist-stats {
      width: 35%;
      background-color: var(--bg-4);
      padding: 1rem;
      height: 22rem;

      .links-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .link {
          display: flex;
          justify-content: space-between;
          font-size: 1.5rem;
          padding: 0.5rem;
          width: 100%;
          color: var(--text-secondary);

          .numbers {
            text-align: right;
            width: 40%;
          }

          &:hover {
            color: var(--highlight-primary) !important;
          }
        }

        .newlink {
          display: flex;
          flex-direction: column;
          border: 0.2rem solid var(--bg-5);
          border-radius: 0.3rem;
          padding: 1rem;
          font-size: 4rem;
          align-items: center;
          justify-content: center;

          &--sub-headers{
            font-size: 1.8rem;;
          }
        }

        .link:nth-child(4) {
          padding-top: 0.5rem;
          border-top: 0.1rem solid var(--text-secondary);
        }
      }
    }

    .new-links-cont {
      flex-direction: row !important;
      margin-top: 3rem;
      justify-content: space-between !important;
    }

    .views-stats {
      width: 100%;
      max-height: 25rem;

      .table{
        font-size: 1.3rem;
        position: relative;

        th{
          position: sticky;
          top: 0;
          z-index: 99;
          background-color: white;
          box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.2);
        }

        td{
          z-index: 9;
        }
        
      }
    }
  }

  .part2-stats{
    display: flex;
    margin-bottom: 6rem;

    &__left{
      width: 35%;
      display: flex;
      flex-direction: column;

    }

    &__right{
      margin-left: 11rem;;
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }

  .select-cont {
    display: flex;
    justify-content: flex-end;
    .pill{
      padding: .1rem;
      margin-left: 1rem;
      cursor: pointer;
      font-weight: 600;
    }

    .pill-active{
      color: var(--highlight-primary);
      border-bottom: .1rem solid var(--highlight-primary);
    }
  }

  .dashboard-content {
    &__stats {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 2rem;
    }

    .totals-cont {
      padding: 2rem 0 1rem 0;
      border-bottom: 0.3rem solid var(--bg-1);
      .select-cont {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__part2 {
      display: flex;
      margin-bottom: 4rem;
      justify-content: space-between;
    }

    &__donations {
      width: 45%;
    }

    &__chart {
      flex: 4;
      &--heading {
        font-size: 1.4rem;
        margin: 2rem 0;
      }

      &--view {
        display: flex;
        justify-content: space-between;
      }

      &--display {
        width: 100%;
      }
    }
  }
}

.vlp {
  width: 100%;
  margin-bottom: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &--text {
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--text-secondary);
    }

    &--value {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .progress-bar {
    background-color: var(--highlight-primary);
  }
}

.stat-card {
  width: 20rem;
  height: auto;
  // box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: var(--bg-1);

  &__heading {
    font-size: 1.4rem;
    font-weight: 500;
  }

  &__count {
    font-size: 3rem;
    font-weight: 800;
  }
}

@import '../../../sass/abstracts/mixins';

.wishlist-main-cont {
  padding: 6.66rem 0 1rem 0;
  background-color: white !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include largeScreen();

  .causes {
    width: 94%;

    .cause-container {
      background-color: white;
      padding: 0;

      .heading-cause {
        width: 100%;
        margin-bottom: 0;

        .title {
          font-size: 1.8rem;

          .l2s {
            height: 4rem;
          }

          .amazon {
            height: 4rem;
          }
        }

        .shape-icon {
          left: 13%;
          display: none;
        }
      }

      .cards-container {
        width: 100%;
        justify-content: flex-start;

        .cards {
          width: calc((100% - 8rem) / 4);
          height: 350px;
          border-radius: 12px;
          border: solid 2px #e1e2ea;
          margin-right: 2rem;

          @include breakpoint(mobileonly) {
            width: calc((100% - 0.3rem) / 1);
            margin-right: 0;
          }

          @include breakpoint(phablet) {
            width: calc((100% - 0.3rem) / 1);
            margin-right: 0;
          }
        }

        .cards:hover {
          background-color: white;
          padding: 1rem 1rem 3rem 1rem;
          border-radius: 12px;
          transition: 0.3s all;
          box-shadow: none !important;

          .cause-title {
            color: var(--light-magenta);
          }
        }
      }
    }
  }

  @include breakpoint(mobileonly) {
    flex-direction: column;
    height: 100%;
  }

  .sub-heading {
    font-family: 'Baloo Da 2';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.5px;
    color: var(--text-secondary);
    text-transform: uppercase;
  }

  .wishlist-cont {
    width: 95%;
    display: flex;

    @include breakpoint(mobileonly) {
      width: 100%;
      align-items: center;
      flex-direction: column;
    }

    .wishlist-part-1 {
      width: 68%;
      padding: 3rem 2rem 0 1rem;

      @include breakpoint(mobileonly) {
        width: 100%;
        padding: 0;
      }

      .about-organisation-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include breakpoint(mobileonly) {
          flex-direction: column;
          align-items: center;
        }

        .banner-cont {
          width: 100%;
          height: 25rem;
          border-radius: 4px;
          background-color: #d8d8d8;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2.2rem;
          margin-bottom: 2rem;
          background-position: center;
          position: relative;

          @include breakpoint(mobileonly) {
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }

        .logo-cont {
          width: 16rem;
          height: 16rem;
          border-radius: 4px;
          background-color: #d8d8d8;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: -3rem;
          left: 0.5rem;
          box-shadow: 2px 2px 0.5rem 0.1rem rgba(0, 0, 0, 0.5);
        }

        .description-cont {
          width: 100%;

          @include breakpoint(mobileonly) {
            width: 100%;
          }

          .organisation-name {
            font-family: 'Baloo Da 2';
            font-size: 2.4rem;
            font-weight: 600;
            color: var(--highlight-primary);
            text-align: left;
            margin-top: 3rem;

            @include breakpoint(mobileonly) {
              text-align: center;
            }
          }

          .description {
            font-family: 'Baloo Da 2';
            font-size: 1.33rem;
            color: #75798a;
            margin: 0 0 1rem 0;
            text-align: left;
            white-space: pre-wrap;

            @include breakpoint(mobileonly) {
              padding: 1rem 2rem;
              margin: 0;
            }

            @include breakpoint(phablet) {
              padding: 1rem 2rem;
              margin: 0;
            }
          }
        }
      }
    }

    .wishlist-part-2 {
      width: 32%;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 3rem 6rem 0 3rem;

      @include breakpoint(mobileonly) {
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-top: 0.2rem solid var(--bg-3);
        padding: 1rem 2rem;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background-color: #323649;
      }

      .service-container {
        margin-bottom: 1rem;
        width: 100%;

        .services {
          display: flex;
          flex-wrap: wrap;
          margin-top: 1.5rem;

          .service-badge {
            font-size: 1.33rem;
            font-family: 'Baloo Da 2';
            display: table;
            line-height: 1.7rem;
            padding: 0.5rem 1.2rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Baloo Da 2';
            width: max-content;
            margin-right: 0.8rem;
            height: 24px;
            border-radius: 6px;
            border: solid 1px #c5c7d3;
            background-color: white;
            color: var(--text-primary);

            @include breakpoint(mobileonly) {
              margin-top: 0.8rem;
            }
          }
        }
      }

      .address-container {
        margin-bottom: 1rem;
        width: 100%;

        .address {
          font-size: 16px;
          color: #424557;
          margin-top: 0.2rem;
          font-family: 'Baloo Da 2';
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          margin-bottom: 1rem;
        }

        .copy {
          cursor: pointer;
          color: var(--text-secondary);
          &:hover {
            color: var(--text-primary);
          }
        }
      }

      .location-container {
        width: 31.5rem;
        margin-bottom: 1rem;
        width: 100%;

        .google-map-cont {
          height: 12.5rem;
          border-radius: 4px;
          background-color: #f6f6f6;
          margin: 1.5rem 0;
        }

        .address-line {
          font-size: 1.33rem;
          color: #75798a;
        }
      }

      .social-media-container {
        width: 100%;

        .icon-main-container {
          display: flex;
          justify-content: flex-start;

          .icon-cont {
            justify-content: space-between;
            display: flex;
            margin-top: 2rem;

            &__icon {
              width: 3rem;
              height: 3rem;
              margin: 0 1rem;
              cursor: pointer;
              &:hover {
                path {
                  fill: var(--highlight-primary);
                }
              }
            }
          }
        }
      }
    }
  }
}

#popover-positioned-bottom {
  z-index: 9;
  .popover-body {
    display: flex;
    padding: 1rem;

    i {
      font-size: 2rem;
      margin: 0 1rem;
      cursor: pointer;
      color: var(--text-secondary);

      &:hover {
        color: var(--highlight-primary);
      }
    }
  }
}

#popover-positioned-bottom1 {
  z-index: 9;
  .popover-body {
    display: flex;
    padding: 0 1rem;

    i {
      font-size: 2rem;
      margin: 0 1rem;
      cursor: pointer;
      color: var(--text-secondary);

      &:hover {
        color: var(--highlight-primary);
      }
    }

    .sharelist__item {
      margin: 1rem 1rem 0 1rem;
    }
  }
}

.share-follow-btn-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.follow-btn {
  font-family: 'Baloo Da 2';
  font-size: 1.4rem;
  outline: none !important;
  padding: 0.3rem;
  height: 3.33rem;
  width: 12rem;
  background-color: white;
  border-radius: 2.3rem;
  border: 0.1rem solid var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-left: 0.5rem;
    color: var(--text-secondary);
  }
}
